import React from "react"
import { graphql } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"
import BannerSection from "../components/Global/TopBanner"
import OurStory from "../components/OurStory/OurStory"
import Methodology from "../components/OurStory/Methodology"
import Partnerships from "../components/OurStory/Partnerships"

const OurStoryPage = ({ data }) => (
  <Layout>
    <SEO
      title="Our Story"
      keywords={[`agile`, `aws`, `shopify`, `boutir`, `T12M Ventures`]}
    />
    <BannerSection
      img={data.img.childImageSharp.fluid}
      title="Why GIANTHOUSE?"
    />
    <OurStory />
    <Methodology img={data.methodImg.childImageSharp.fluid} />
    <Partnerships
      awsLogo={data.awsLogo.childImageSharp.fluid}
      shopifyLogo={data.shopifyLogo.childImageSharp.fluid}
      t12mLogo={data.t12mLogo.childImageSharp.fluid}
      boutirLogo={data.boutirLogo.childImageSharp.fluid}
      msLogo={data.msLogo.childImageSharp.fluid}
      hktdcLogo={data.hktdcLogo.childImageSharp.fluid}
    />
  </Layout>
)

export const query = graphql`
  {
    img: file(relativePath: { eq: "our-story-banner.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1920, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }

    methodImg: file(relativePath: { eq: "methodology.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1024, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }

    awsLogo: file(relativePath: { eq: "aws-logo.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 300, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }

    shopifyLogo: file(relativePath: { eq: "shopify-logo.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 300, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }

    t12mLogo: file(relativePath: { eq: "t12m-logo.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 300, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }

    boutirLogo: file(relativePath: { eq: "boutir-logo.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 300, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }

    msLogo: file(relativePath: { eq: "ms-logo.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 100, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }

    hktdcLogo: file(relativePath: { eq: "hktdc-logo.jpg" }) {
      childImageSharp {
        fluid(maxHeight: 140, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
  }
`
export default OurStoryPage
