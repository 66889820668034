import React from "react"
import Title from "../Global/Title"
import Img from "gatsby-image"

export default function Methodology({ img }) {
  return (
    <section className="py-5">
      <div className="container">
        <div className="row">
          <div className="col-10 col-sm-10 mx-auto text-center">
            <Title title="Methodology" />
            <div className="separator" />
            <Img fluid={img} />
            <br />
            <p className="mb-5">
              Agile project management is an approach to delivering a project throughout its life cycle. Iterative or
              agile life cycles are composed of iterations or incremental steps towards the completion of a project.
            </p>
            <p className="mb-5">
              Agile approach is used in software development to promote velocity and adaptability, since the benefit of
              iteration is that adjustments can be made along the product development cycle rather than following a
              linear path. One of the aims of an agile approach is to enhance business value throughout the process
              rather than only at the end.
            </p>
          </div>
        </div>
      </div>
    </section>
  )
}
