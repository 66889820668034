import React from "react"
import Title from "../Global/Title"

export default function AboutGianthouse({ img }) {
  return (
    <section className="white-container">
      <div className="container">
        <div className="row">
          <div className="col-10 col-sm-8 mx-auto text-center">
            <Title title="Our Story" />
            <div className="separator" />
            <p className="mb-5">
              Our brand name GIANTHOUSE was inspired by the giant house spider, one of the fastest invertebrates known
              on earth. We adopt Agile methodology, in addition to traditional waterfall product development approach,
              for projects that require continuous enhancement and high level of flexibility. With the adaptive mindset,
              we are committed to deliver quality digital services in a responsive and timely manner.
            </p>
          </div>
        </div>
      </div>
    </section>
  )
}
