import React from "react"
import Title from "../Global/Title"
import Img from "gatsby-image"

export default function Partnerships({
  awsLogo,
  shopifyLogo,
  t12mLogo,
  boutirLogo,
  hktdcLogo,
  msLogo,
}) {
  return (
    <section className="white-container">
      <div className="container">
        <div className="row">
          <div className="col-10 col-sm-8 mx-auto text-center">
            <Title title="Partnerships" />
            <div className="separator" />
            <p className="mb-5">
              We are strongly supported by our technical and business partners.
            </p>
            <div className="partner-row">
              <Img fluid={awsLogo} />
              <Img fluid={shopifyLogo} />
              <Img fluid={t12mLogo} />
              <Img fluid={boutirLogo} />
              <Img fluid={hktdcLogo} />
              <Img
                fluid={msLogo}
                imgStyle={{
                  objectFit: "contain",
                }}
              />
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}
